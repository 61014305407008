import React, { useEffect } from "react";

import {
  Stack,
  FormControl,
  TextField,
  FormLabel,
  Paper,
  Divider,
  Box,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Card,
  Typography,
  InputAdornment,
} from "@mui/material";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";

import { builder_step, step } from "../../../Config/types";

export default function StepForm(props: {
  index: number | null;
  step: step;
  setSelectedStep: React.Dispatch<React.SetStateAction<builder_step>>;
}) {
  /* defaultValues used on initial load and also when data is copied in from the builder */
  const defaultValues = {
    anchorElement: { selector: "", innerText: "", disableOutline: false },
    nextTrigger: "onPageNav",
    dialogBox: { width: 300, translateX: 0, translateY: 0 },
  } as builder_step;

  /* using local state here for formStep so that default values can be easily set */
  const [formStep, setFormStep] = React.useState<builder_step>({
    ...defaultValues,
    ...props.step,
  });

  useEffect(() => {
    /* quick comparison to prevent loop */
    if (JSON.stringify(props.step) !== JSON.stringify(formStep))
      setFormStep({
        ...defaultValues,
        ...props.step,
      });
  }, [props.step]);

  useEffect(() => {
    props.setSelectedStep(formStep);
  }, [formStep]);

  const handleInputChange = (e: any) => {
    /* here we update the stepArray state without using setStepArray because we do not want a re-render on input changes */
    let property = e.target.id;

    /* need to update both the local formStep (for rendering) and the inherited stepArray (for changes from above) */
    const tempFormStep = JSON.parse(JSON.stringify(formStep));
    if (
      property &&
      ["width", "translateX", "translateY"].includes(property as string)
    ) {
      tempFormStep.dialogBox[property] = Number(e.target.value);
    } else if (
      property &&
      ["selector", "innerText"].includes(property as string)
    ) {
      tempFormStep.anchorElement[property] = e.target.value;
    } else {
      tempFormStep[property] = e.target.value;
    }

    setFormStep(tempFormStep);
  };

  const handleRadioChange = (e: any) => {
    /* here we update the stepArray state without using setStepArray because we do not want a re-render on input changes */
    let property = "nextTrigger";

    /* need to update both the local formStep (for rendering) and the inherited stepArray (for changes from above) */
    const tempFormStep = JSON.parse(JSON.stringify(formStep));
    tempFormStep[property] = e.target.value;

    setFormStep(tempFormStep);
  };

  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        m: 2,
        display: "flex",
        flexDirection: "column",
        bgcolor: "primary.light",
        border: 1,
        borderStyle: "dashed",
        borderColor: "secondary.main",
      }}
    >
      <form id="StepEditorForm">
        <Stack spacing={1}>
          <TextField
            id="url"
            label="Url"
            value={formStep.url || ""}
            color="secondary"
            type="search"
            size="small"
            margin="dense"
            multiline
            style={{
              width: "100%",
              background: "#FFFFFF",
            }}
            onChange={handleInputChange}
          />
          <Divider
            sx={{
              fontSize: "small",
              mt: 2,
              color: "secondary.dark",
            }}
          >
            Anchor Element
          </Divider>
          <Stack direction="row">
            <TextField
              id="selector"
              label="Selector"
              required
              value={formStep.anchorElement?.selector || ""}
              color="secondary"
              type="search"
              size="small"
              margin="dense"
              multiline
              style={{
                width: "100%",
                background: "#FFFFFF",
              }}
              onChange={handleInputChange}
            />
            <Box margin={"auto"}>
              <Box mt={"5px"}></Box>
            </Box>
          </Stack>
          <TextField
            id="innerText"
            label="Text"
            value={formStep.anchorElement?.innerText || ""}
            color="secondary"
            type="search"
            size="small"
            margin="dense"
            multiline
            maxRows={3}
            style={{
              width: "100%",
              background: "#FFFFFF",
            }}
            onChange={handleInputChange}
          />

          <TextField
            id="contextDiv"
            label="Context element"
            value={formStep.contextDiv || ""}
            color="secondary"
            type="search"
            size="small"
            margin="dense"
            style={{
              width: "100%",
              background: "#FFFFFF",
            }}
            onChange={handleInputChange}
          />

          <Divider
            sx={{
              fontSize: "small",
              mt: 2,
              color: "secondary.dark",
            }}
          >
            Navigation
          </Divider>
          <FormControl color="secondary">
            <FormLabel id="buttons-group-label">
              How will the user navigate to the next step?
            </FormLabel>
            <ToggleButtonGroup
              fullWidth
              exclusive
              size="small"
              color="secondary"
              id="nextTrigger"
              aria-labelledby="buttons-group-label"
              value={formStep.nextTrigger || defaultValues.nextTrigger}
              onChange={handleRadioChange}
            >
              <Tooltip
                placement="top-start"
                arrow
                title="By clicking the relevant page element"
              >
                <ToggleButton value="onPageNav">Click</ToggleButton>
              </Tooltip>

              <Tooltip
                placement="top"
                arrow
                title="By clicking the 'next' button on the popup"
              >
                <ToggleButton value="dialogButton">Next</ToggleButton>
              </Tooltip>

              <Tooltip
                placement="top-end"
                arrow
                title="By navigating to the next page"
              >
                <ToggleButton value="userNav">Navigate</ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </FormControl>

          <Divider
            sx={{
              fontSize: "small",
              mt: 2,
              color: "secondary.dark",
            }}
          >
            Placement
          </Divider>

          <Box width={"100%"} pb={1}>
            <Grid container spacing={2} width={"100%"}>
              <Grid item xs={4} spacing={0}>
                <Stack direction="row" spacing={0}>
                  <Tooltip
                    placement="top-end"
                    arrow
                    title={
                      formStep.anchorElement!.disableOutline
                        ? "Show Outline"
                        : "Hide Outline"
                    }
                  >
                    <ToggleButton
                      fullWidth
                      size="small"
                      id="disableOutline"
                      value="disableOutline"
                      selected={
                        !formStep.anchorElement?.disableOutline || false
                      }
                      onChange={() => {
                        const tempFormStep = JSON.parse(
                          JSON.stringify(formStep)
                        );
                        tempFormStep.anchorElement["disableOutline"] =
                          !tempFormStep.anchorElement["disableOutline"];

                        setFormStep(tempFormStep);
                      }}
                      sx={{
                        p: "5px",
                        ml: 1,
                        borderColor: "secondary2.light",
                        borderStyle: "solid",
                        borderWidth: 3,
                        "&.Mui-selected, &.Mui-selected:hover": {
                          borderColor: "secondary.main",
                        },
                      }}
                    >
                      {formStep.anchorElement?.disableOutline ? (
                        <DoNotDisturbIcon color="info" />
                      ) : (
                        <CheckCircleOutlineIcon color="info" />
                      )}
                    </ToggleButton>
                  </Tooltip>
                  <VerticalAlignBottomIcon
                    sx={{
                      color: "divider",
                      my: "auto",
                      ml: 1,
                      rotate: "-90deg",
                    }}
                  />
                </Stack>
                <VerticalAlignBottomIcon
                  sx={{ color: "divider", mx: "auto", width: "100%", mt: 1 }}
                />
                <TextField
                  id="translateY"
                  label="Move down"
                  value={
                    formStep.dialogBox?.translateY ||
                    defaultValues.dialogBox?.translateY
                  }
                  color="secondary"
                  type="number"
                  size="small"
                  margin="dense"
                  style={{
                    background: "#FFFFFF",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">px</InputAdornment>
                    ),
                  }}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={8} spacing={2}>
                <Stack direction="row" spacing={2}>
                  <TextField
                    id="translateX"
                    label="Move right"
                    value={
                      formStep.dialogBox?.translateX ||
                      defaultValues.dialogBox?.translateX
                    }
                    color="secondary"
                    type="number"
                    size="small"
                    margin="dense"
                    style={{
                      background: "#FFFFFF",
                      width: "50%",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">px</InputAdornment>
                      ),
                    }}
                    onChange={handleInputChange}
                  />
                </Stack>
                <Card
                  elevation={4}
                  sx={{
                    mt: 2,
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    bgcolor: "primary.light",

                    boxShadow: (theme) =>
                      `0em 0px 0.8em rgb(0 0 0 / 30%), 6px 6px ${theme.palette.divider}`,
                  }}
                >
                  <Typography
                    fontSize={"24px"}
                    fontWeight={"bold"}
                    color="divider"
                    fontStyle={"italic"}
                    align="left"
                  >
                    Step
                  </Typography>
                  <TextField
                    id="width"
                    label="Width"
                    value={
                      formStep.dialogBox?.width ||
                      defaultValues.dialogBox?.width
                    }
                    color="secondary"
                    type="number"
                    size="small"
                    margin="dense"
                    style={{
                      // width: "33%",
                      background: "#FFFFFF",
                    }}
                    onChange={handleInputChange}
                  />
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </form>
    </Paper>
  );
}


